/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { Grid, Icon, IconButton } from "@mui/material";
import lod_ from "lodash";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";

export default function Step4Confirm({ validStep, choice }) {
	useEffect(() => {
		validStep();
	}, []);

	function getChoice(choice) {
		switch (choice) {
			case "add":
				return "Les données seront ajoutées aux données éxistantes.";
			case "remove":
				return "Attention, cette action va remplacer toutes les données en base de données.\n\nVoulez vous confirmer ?";
			default:
				return "Les données seront ajoutées aux données éxistantes.";
		}
	}

	return (
		<MDBox
			sx={{ ml: 30, mr: 30 }}
			style={{
				height: "100%"
			}}
		>
			<MDBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%",
					whiteSpace: "break-spaces"
				}}
			>
				<MDTypography color={choice === "remove" ? "error" : ""} variant="h3">
					{getChoice(choice)}
				</MDTypography>
			</MDBox>
		</MDBox>
	);
}
