/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import lod_ from "lodash";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import { createDictionarySkeleton } from "helpers/form";

export default function EditMetadatas({
	open = false,
	handleCloseDialog = () => {},
	handleSave = () => {},
	selectedRows = [],
	metadatasDictionary = {}
}) {
	const [datas, setDatas] = useState({});
	// Metadatas skeleton
	const [metadatasSkeleton, setMetadatasSkeleton] = useState(
		createDictionarySkeleton(metadatasDictionary, {})
	);
	// Close dialog
	function close() {
		handleCloseDialog();
	}
	// Save source
	async function submit() {
		handleSave(datas);
		close();
	}
	// Change metadatas
	const handleChange = (path, value) => {
		let completePath = `metadatas.${path}`;
		if (value?.trim()) {
			datas[completePath] = value;
		} else {
			delete datas[completePath];
		}

		let clone = lod_.cloneDeep(metadatasSkeleton);
		lod_.set(clone, path, value);
		setMetadatasSkeleton(clone);
	};

	useEffect(() => {
		setDatas({});
		setMetadatasSkeleton(createDictionarySkeleton(metadatasDictionary, {}));
	}, [open]);

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={() => close()}>
			<DialogTitle>
				<MDBox>
					<MDTypography variant="h5">{`Modifier les métadonnées de ${selectedRows.length} sources`}</MDTypography>
					<MDTypography variant="body2">
						Cette modification écrasera les métadonnées des sources et de leurs connaissances
					</MDTypography>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<MDBox
					sx={{ ml: 10, mr: 10 }}
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					<MDBox sx={{ mt: 2 }}>
						<MDTypography variant="h6">{i18n.t("SOURCES.metadatas")}</MDTypography>
						<LittleForm
							object={metadatasDictionary}
							metadatasSkeleton={metadatasSkeleton}
							handleChange={handleChange}
						/>
					</MDBox>
					{/* HTML */}
					<MDBox mt={4}>
						<MDTypography variant="h6">Options site web</MDTypography>
						<MDInput
							sx={{ mt: 2 }}
							fullWidth
							label="HTML Selector"
							value={metadatasSkeleton.htmlSelector}
							onChange={e => {
								handleChange("htmlSelector", e.target.value);
							}}
						/>
					</MDBox>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={() => close()}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton variant="contained" color="info" onClick={submit}>
					{i18n.t("SETTINGS.save")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
