/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import MDTypography from "components/Basics/MDTypography";
import { Icon, InputAdornment } from "@mui/material";
import lod_ from "lodash";
import { LittleForm } from "components/Custom/LittleForm";
import MDInput from "components/Basics/MDInput";
import { createDictionarySkeleton } from "helpers/form";

const NameInput = ({ name, changeName }) => {
	let extension = `.${name.split(".").pop()}`;
	let nameWithoutExtension = name.split(".").slice(0, -1).join(".");

	const [newName, setNewName] = useState(nameWithoutExtension);

	useEffect(() => {
		let completeName = `${newName}${extension}`;
		changeName(completeName);
	}, [newName]);

	return (
		<MDInput
			// disabled
			className="dialogInput"
			label="Nom du fichier"
			value={newName}
			onChange={e => setNewName(e.target.value)}
			InputProps={{
				endAdornment: <InputAdornment position="end">{extension}</InputAdornment>
			}}
		/>
	);
};

export default function Step1Metadatas({
	skeleton,
	validStep,
	handleSave,
	handleSaveFileName,
	metadatas
}) {
	const [metadatasSkeleton, setMetadatasSkeleton] = useState(
		createDictionarySkeleton(metadatas, skeleton.metadatas)
	);

	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(metadatasSkeleton);
		lod_.set(clone, path, value);
		handleSave(clone);
		setMetadatasSkeleton(clone);
	};

	useEffect(() => {
		validStep();
		handleSave(metadatasSkeleton);
	}, []);

	return (
		<MDBox sx={{ ml: 10, mr: 10 }} display="flex" flexDirection="column" justifyContent="center">
			<MDBox flex="1">
				<MDBox display="flex" alignItems="center" flexDirection="row">
					<MDBox display="flex" alignItems="center" flex="1">
						<Icon fontSize="large">description</Icon>
						<MDBox ml={1} mr={1} flex="1">
							<NameInput
								name={lod_.get(skeleton, "file.name")}
								changeName={newName => {
									handleSaveFileName(newName);
								}}
							/>
						</MDBox>
					</MDBox>

					<MDBox>
						<a href={skeleton.url} target="_blank">
							<MDButton color="info">{i18n.t("SETTINGS.visualize")}</MDButton>
						</a>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox flex="2" mt={5}>
				<MDTypography variant="h6">{i18n.t("SOURCES.metadatas")}</MDTypography>
				<MDBox mt={2}>
					<LittleForm
						object={metadatas}
						metadatasSkeleton={metadatasSkeleton}
						handleChange={handleChange}
					/>
				</MDBox>
			</MDBox>
			{/* HTML */}
			{skeleton.type === "website" && (
				<MDBox mt={4}>
					<MDTypography variant="h5">Options site web</MDTypography>
					<MDInput
						sx={{ mt: 2 }}
						fullWidth
						label="HTML Selector"
						value={skeleton.metadatas.htmlSelector}
						onChange={e => {
							handleChange("htmlSelector", e.target.value);
						}}
					/>
				</MDBox>
			)}
		</MDBox>
	);
}
