import MDBox from "components/Basics/MDBox";
import { Card, CardActions, CardContent, Icon } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import i18n from "i18n";
import MDBadge from "components/Basics/MDBadge";
import { DateTime } from "luxon";

const PlanBadge = ({ value }) => {
	return (
		<MDBadge
			badgeContent={value ? i18n.t("SETTINGS.activated") : i18n.t("SETTINGS.deactivated")}
			container
			color={value ? "success" : "error"}
		/>
	);
};
/*
 * Main component
 * Subscription part of the settings page
 */
const SubscriptionPart = ({ permissions, settings }) => {
	const displayPlanDetails = plan => {
		let autoTranslate = Boolean(plan.config.autoTranslate);
		let chatAllowed = Boolean(plan.config.chatAllowed);
		return (
			<MDBox mt={2}>
				<MDBox display="flex" alignItems="center" justifyContent="space-between">
					<MDTypography variant="body1">{i18n.t("SETTINGS.PLAN.autoTranslate")}</MDTypography>
					<PlanBadge value={autoTranslate} />
				</MDBox>
				<MDBox display="flex" alignItems="center" justifyContent="space-between">
					<MDTypography variant="body1">{i18n.t("SETTINGS.PLAN.chatAllowed")}</MDTypography>
					<PlanBadge value={chatAllowed} />
				</MDBox>
			</MDBox>
		);
	};

	const getCurrentBillingPeriod = billingPeriod => {
		switch (billingPeriod) {
			case "monthly":
				return (
					<>
						{/* with luxon get first day of current month */}
						<MDTypography variant="body1">
							{`Du ${new Date(DateTime.local().startOf("month").toISO()).toLocaleDateString(
								"fr-FR",
								{
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric"
								}
							)}`}
						</MDTypography>
						{/* with luxon get last day of current month */}
						<MDTypography variant="body1">
							{`Au ${new Date(DateTime.local().endOf("month").toISO()).toLocaleDateString("fr-FR", {
								weekday: "long",
								year: "numeric",
								month: "long",
								day: "numeric"
							})}`}
						</MDTypography>
					</>
				);
			case "yearly":
				return (
					<>
						{/* with luxon get first day of current month */}
						<MDTypography variant="body1">
							{`Du ${new Date(DateTime.local().startOf("year").toISO()).toLocaleDateString(
								"fr-FR",
								{
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric"
								}
							)}`}
						</MDTypography>
						{/* with luxon get last day of current month */}
						<MDTypography variant="body1">
							{`Au ${new Date(DateTime.local().endOf("year").toISO()).toLocaleDateString("fr-FR", {
								weekday: "long",
								year: "numeric",
								month: "long",
								day: "numeric"
							})}`}
						</MDTypography>
					</>
				);
			default:
				return null;
		}
	};

	const hasAccessToAccount = () => {
		return Boolean(permissions && permissions.includes("access:accountManager"));
	};

	return (
		<>
			<MDTypography variant="h3">{i18n.t("CONFIG.subscription")}</MDTypography>
			<MDBox display="flex" mt={3}>
				<MDBox flex="1" mr={2}>
					<Card
						className="stats_planCard"
						style={{
							justifyContent: "space-between"
						}}
					>
						<CardContent>
							<MDBox display="flex" alignItems="center" justifyContent="space-between">
								<MDTypography variant="h6">{i18n.t("CONFIG.subscription")}</MDTypography>
								<MDBadge
									badgeContent={i18n.t(`SETTINGS.PLAN.${settings.plan.type}`)}
									container
									size="lg"
									color="info"
								/>
							</MDBox>
							{displayPlanDetails(settings.plan)}
						</CardContent>
						<CardActions
							style={{
								justifyContent: "flex-end"
							}}
						>
							{hasAccessToAccount() && settings.accountManager && (
								<MDButton
									size="medium"
									color="info"
									className="stats_linkButton"
									component="a"
									href={settings.accountManager}
									target="_blank"
								>
									<Icon>auto_awesome</Icon>&nbsp;{i18n.t("CONFIG.PLAN.seeAll")}
								</MDButton>
							)}
						</CardActions>
					</Card>
				</MDBox>
				<MDBox flex="1">
					<Card
						className="stats_planCard"
						style={{
							justifyContent: "space-between"
						}}
					>
						<CardContent>
							<MDBox display="flex" alignItems="center" justifyContent="space-between">
								<MDTypography variant="h6">{i18n.t("CONFIG.PLAN.billingPeriod")}</MDTypography>
								<MDBadge
									badgeContent={i18n.t(`SETTINGS.PLAN.${settings.plan.billingPeriod}`)}
									container
									size="lg"
									color="info"
								/>
							</MDBox>

							<MDBox mt={3}>
								<MDTypography variant="h6">Période en cours</MDTypography>
								{getCurrentBillingPeriod(settings.plan.billingPeriod)}
							</MDBox>
						</CardContent>
					</Card>
				</MDBox>
				<MDBox flex="1" ml={2}>
					<Card
						className="stats_planCard"
						style={{
							justifyContent: "space-between"
						}}
					>
						<CardContent>
							<MDTypography variant="h6">{i18n.t("CONFIG.PLAN.start")}</MDTypography>
							<MDTypography variant="body1">
								{`Le ${new Date(settings.plan.billingStart).toLocaleDateString("fr-FR", {
									weekday: "long",
									year: "numeric",
									month: "long",
									day: "numeric"
								})}`}
							</MDTypography>
						</CardContent>
					</Card>
				</MDBox>
			</MDBox>
		</>
	);
};

export default SubscriptionPart;
