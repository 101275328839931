/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import "./style.css";
import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import i18n from "i18n";

// Material Dashboard 2 PRO React components
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "components/Advanced/Sidenav/SidenavCollapse";
import SidenavList from "components/Advanced/Sidenav/SidenavList";
import SidenavItem from "components/Advanced/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "components/Advanced/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Advanced/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setMiniSidenav } from "context";
import { useSelector, useDispatch } from "react-redux";
import lod_ from "lodash";
import MDAvatar from "components/Basics/MDAvatar";
import { useAuth0 } from "react-auth0-wrapper";
import { hasPermissions } from "App";
import { sidenavLogoImage } from "./styles/sidenav";
import { changeRouteHandle } from "redux-react/reducers/applicationReducer";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
	const [openCollapse, setOpenCollapse] = useState(false);
	const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, darkMode, transparentSidenav, whiteSidenav } = controller;
	const location = useLocation();
	const { pathname } = location;
	const collapseName = decodeURIComponent(pathname.split("/").slice(1)[0]);
	const items = pathname.split("/").slice(1);
	const itemParentName = items[1];
	const itemName = decodeURIComponent(items[items.length - 1]);
	const { profile, user } = useSelector(state => state);
	const userPermissions = useSelector(state => state.permissions.permissions);
	const { logout } = useAuth0();
	const reduxDispatch = useDispatch();

	const { disableRouting } = useSelector(state => state.application);

	const textColor = "white";

	const closeSidenav = () => setMiniSidenav(dispatch, true);

	useEffect(() => {
		setOpenCollapse(collapseName);
		setOpenNestedCollapse(itemParentName);
	}, []);

	useEffect(() => {
		// A function that sets the mini state of the sidenav.
		function handleMiniSidenav() {
			setMiniSidenav(dispatch, window.innerWidth < 1200);
		}

		/**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
		window.addEventListener("resize", handleMiniSidenav);

		// Call the handleMiniSidenav function to set the state with the initial value.
		handleMiniSidenav();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleMiniSidenav);
	}, [dispatch, location]);

	// Render all the nested collapse items from the routes.js
	const renderNestedCollapse = collapse => {
		const template = collapse.map(({ name, route, href }, index) =>
			href ? (
				<Link
					key={index}
					href={href}
					target="_blank"
					rel="noreferrer"
					sx={{ textDecoration: "none" }}
				>
					<SidenavItem name={name} nested />
				</Link>
			) : (
				<NavLink to={route} key={index} sx={{ textDecoration: "none" }}>
					<SidenavItem name={name} active={route === pathname} nested />
				</NavLink>
			)
		);

		return template;
	};
	// Render the all the collpases from the routes.js
	const renderCollapse = collapses =>
		collapses.map(({ name, collapse, route, href }, index) => {
			let returnValue;

			let key = "";
			if (route) {
				key = route.split("/").slice(-1)[0];
			}

			if (collapse) {
				returnValue = (
					<SidenavItem
						key={index}
						color={color}
						name={name}
						active={index === itemParentName ? "isParent" : false}
						open={openNestedCollapse === index}
						onClick={({ currentTarget }) =>
							openNestedCollapse === index && currentTarget.classList.contains("MuiListItem-root")
								? setOpenNestedCollapse(false)
								: setOpenNestedCollapse(index)
						}
					>
						{renderNestedCollapse(collapse)}
					</SidenavItem>
				);
			} else {
				returnValue = href ? (
					<Link
						href={href}
						key={index}
						target="_blank"
						rel="noreferrer"
						sx={{ textDecoration: "none" }}
					>
						<SidenavItem color={color} name={name} active={index === itemName} />
					</Link>
				) : (
					<NavLink to={route} key={index} sx={{ textDecoration: "none" }}>
						<SidenavItem color={color} name={name} active={key === itemName} />
					</NavLink>
				);
			}
			return <SidenavList key={index}>{returnValue}</SidenavList>;
		});

	// Render all the routes from the routes.js (All the visible items on the Sidenav)
	const renderRoutes = routes.map(
		({ type, name, icon, collapse, href, route, permissions }, index) => {
			if (permissions) {
				if (!hasPermissions(permissions, userPermissions)) {
					return null;
				}
			}

			let returnValue;
			let noCollapse = Boolean(!collapse);
			/**
			 * Possible type :
			 * - title : Title in the sidenav
			 * - divider : Divider in the sidenav
			 * - route : Route in the sidenav
			 */

			let key = "";
			if (route) {
				key = route.slice(1);
			}

			switch (type) {
				case "title":
					returnValue = (
						<MDTypography
							className={miniSidenav ? " sidenavTitle reduceSidenavTitle" : "sidenavTitle"}
							key={index}
							color={textColor}
							display="block"
							variant="caption"
							fontWeight="bold"
							textTransform="uppercase"
							pl={3}
							mt={2}
							mb={1}
							ml={1}
						>
							{name}
						</MDTypography>
					);
					break;
				case "divider":
					returnValue = <Divider key={index} light />;
					break;
				case "route":
					if (href) {
						returnValue = (
							<Link
								href={href}
								key={index}
								target="_blank"
								rel="noreferrer"
								sx={{ textDecoration: "none" }}
							>
								<SidenavCollapse
									name={name}
									icon={icon}
									active={key === collapseName}
									noCollapse={noCollapse}
								/>
							</Link>
						);
					} else if (route && !collapse) {
						returnValue = (
							<NavLink
								to={route}
								key={index}
								onClick={async e => {
									reduxDispatch(changeRouteHandle(route));

									if (disableRouting) {
										e.preventDefault();
									}
								}}
							>
								<SidenavCollapse
									name={name}
									icon={icon}
									noCollapse={noCollapse}
									active={key === collapseName}
								>
									{collapse ? renderCollapse(collapse) : null}
								</SidenavCollapse>
							</NavLink>
						);
					} else {
						returnValue = (
							<SidenavCollapse
								key={index}
								name={name}
								icon={icon}
								active={key === collapseName}
								open={openCollapse === index}
								onClick={() =>
									openCollapse === index ? setOpenCollapse(false) : setOpenCollapse(index)
								}
							>
								{collapse ? renderCollapse(collapse) : null}
							</SidenavCollapse>
						);
					}
					break;
				default:
					break;
			}

			return returnValue;
		}
	);

	function displayMenuSelection() {
		if (!lod_.isEmpty(profile)) {
			return <List>{renderRoutes}</List>;
		} else if (!lod_.isEmpty(user)) {
			return (
				<List
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						height: "88%"
					}}
				>
					<SidenavCollapse
						name={`${user.first} ${user.last}`}
						icon={
							<MDAvatar
								bgColor="dark"
								src={user.picture}
								alt={`${user.first} ${user.last}`}
								size="sm"
							>
								{lod_.isEmpty(user.picture) ? "" : user.first[0]?.toUpperCase()}
							</MDAvatar>
						}
						active
						noCollapse
					></SidenavCollapse>
					<SidenavCollapse
						name={i18n.t("ROUTES.logout")}
						icon={<Icon fontSize="medium">logout</Icon>}
						active={false}
						noCollapse
						onClick={() => {
							logout({ returnTo: window.location.origin });
						}}
					></SidenavCollapse>
				</List>
			);
		}
		return null;
	}

	return (
		<SidenavRoot {...rest} variant="permanent" ownerState={{ miniSidenav, darkMode }}>
			<MDBox pt={1} pb={1} px={1} textAlign="center">
				<MDBox
					display={{ xs: "block", xl: "none" }}
					position="absolute"
					top={0}
					right={0}
					p={1.625}
					onClick={closeSidenav}
					sx={{ cursor: "pointer" }}
				>
					<MDTypography variant="h6" color="secondary">
						<Icon sx={{ fontWeight: "bold" }}>close</Icon>
					</MDTypography>
				</MDBox>

				<MDBox
					component={NavLink}
					to="/"
					display="flex"
					alignItems="center"
					style={{
						justifyContent: miniSidenav ? "center" : ""
					}}
				>
					{brand && (
						<MDBox
							className="brandLogo"
							component="img"
							src={brand}
							alt="Brand"
							sx={theme => sidenavLogoImage(theme, { miniSidenav })}
						/>
					)}
					{!miniSidenav && (
						<MDBox
							style={{
								marginLeft: "5px",
								textAlign: "left",
								width: "70%"
							}}
						>
							<MDTypography
								component="h6"
								variant="button"
								fontWeight="medium"
								color={textColor}
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis"
								}}
							>
								{brandName}
							</MDTypography>
						</MDBox>
					)}
				</MDBox>
			</MDBox>
			<Divider light />
			{displayMenuSelection()}
		</SidenavRoot>
	);
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
	color: "info",
	brand: "",
	brandName: ""
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
	color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
	brand: PropTypes.string,
	brandName: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Sidenav;
