/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import lod_ from "lodash";
import InformationCard from "pages/settings/charts/steps/components/InformationCard";

export default function Step3Choice({ validStep, choice, handleChoice }) {
	// const [choice, setChoice] = useState("add");

	function handleChange(value) {
		// setChoice(value);
		handleChoice(value);
	}

	useEffect(() => {
		validStep();
	}, []);

	return (
		<MDBox
			sx={{ ml: 30, mr: 30 }}
			style={{
				height: "100%"
			}}
		>
			<MDBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%"
				}}
			>
				<MDBox
					style={{
						width: "100%"
					}}
				>
					<InformationCard
						element={{
							title: "Ajouter les données",
							description: "Ajouter les données aux données éxistantes"
						}}
						onClick={() => handleChange("add")}
						selected={choice === "add"}
					/>
				</MDBox>
				<MDBox
					style={{
						width: "100%"
					}}
				>
					<InformationCard
						element={{
							title: "Remplacer les données",
							description:
								"Remplacer les données éxistantes par les nouvelles, attention cela supprimera les données en base de données"
						}}
						onClick={() => handleChange("remove")}
						selected={choice === "remove"}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
}
