/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { Divider, Icon, IconButton, Menu, MenuItem } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import ProfileActions from "redux-react/actions/profileActions";
import SettingsActions from "redux-react/actions/settingsActions";

import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import ConfirmDialogButton from "components/Custom/Dialogs/ConfirmDialogButton";
import MDTypography from "components/Basics/MDTypography";
import FilterDialog from "./FilterDialog";

export function createCode(assistantID) {
	const alpha = "ABCEHLSTUX";
	let mID = assistantID[0] + assistantID[2] + assistantID[assistantID.length - 1] + "_";
	let currentMilli = Date.now();
	let milli = "" + currentMilli;
	mID += milli.substring(2, 8) + "_";
	milli = milli.substring(8);
	mID += alpha[milli[0]] + milli[1] + milli[2] + alpha[milli[3]] + alpha[milli[4]];
	return mID;
}

export default function SettingsFilters({ route }) {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();

	const [paginedList, setPaginedList] = useState(null);

	const [addNewFilter, setAddNewFilter] = useState(false);
	const [editFilter, setEditFilter] = useState({
		open: false
	});

	const [reloadTable, setReloadTable] = useState(false);

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList[0]);
		};

		dispatch(ChartsActions.getPaginedList(profile.assistantID, route, onSuccess));
	}

	const actionDeleteHandle = filter => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.FILTERS.SUCCESS.delete"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			dispatch(ProfileActions.updateMenu(profile.assistantID));
		};
		dispatch(
			SettingsActions.deleteSetting(
				profile.assistantID,
				"filters",
				{ code: filter.code },
				onSuccess
			)
		);
	};

	const actionEditHandle = filter => {
		setEditFilter({
			open: true,
			...filter
		});
	};

	useEffect(() => {
		loadPaginedList();
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox display="flex">
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							color="info"
							onClick={() => setAddNewFilter(true)}
						>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.FILTERS.add")}
						</MDButton>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<>
					<DefaultDataTable
						route={route}
						id={paginedList.code}
						reloadTable={reloadTable}
						dictionary={paginedList.dictionary}
						form={route.form}
						list={paginedList}
						pagination={paginedList.pagination}
						canSearch
						table={paginedList.data}
						display={paginedList.request.attributesDisplay}
						optionsAction="left"
						// actionEditHandle={actionEditHandle}
						// actionDeleteHandle={actionDeleteHandle}

						actions={[
							<IconButton
								handleclick={({ values }, e) => {
									setMenuValues(values);
									setMenuRef(e.currentTarget);
								}}
							>
								<Icon fontSize="medium">more_vert</Icon>
							</IconButton>
						]}
					/>
					{/* Menu actions selection */}
					<Menu
						open={Boolean(menuRef)}
						anchorEl={menuRef}
						onClose={() => {
							setMenuRef(null);
						}}
					>
						{/* Options */}
						<MenuItem disabled style={{ opacity: 1 }}>
							<MDTypography variant="caption">Edition</MDTypography>
						</MenuItem>
						<MenuItem
							disabled={menuValues.type === "multiCollection"}
							onClick={() => {
								actionEditHandle(menuValues);
								setMenuRef(null);
							}}
						>
							<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
						</MenuItem>

						<ConfirmDialogButton
							onConfirm={(e, values) => {
								actionDeleteHandle(values);
								setMenuRef(null);
							}}
							component={
								<MenuItem>
									<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
								</MenuItem>
							}
							rowValues={menuValues}
							displayAttribute="name"
						/>
					</Menu>
				</>
			)}
			{/* Add */}
			{paginedList && (
				<FilterDialog
					open={addNewFilter}
					handleCloseDialog={() => setAddNewFilter(false)}
					handleSave={() => {
						setReloadTable(!reloadTable);
						dispatch(ProfileActions.updateMenu(profile.assistantID));
					}}
					dictionary={paginedList.dictionary}
					filter={{}}
					edit={false}
				/>
			)}
			{/* Edit */}
			{paginedList && (
				<FilterDialog
					open={editFilter.open}
					handleCloseDialog={() => setEditFilter({ open: false })}
					handleSave={() => {
						setReloadTable(!reloadTable);
					}}
					dictionary={paginedList.dictionary}
					filter={editFilter}
					edit
				/>
			)}
		</DashboardLayout>
	);
}
