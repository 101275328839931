/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useRef, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import lod_ from "lodash";
import Step1View from "./steps/1. View";
import Step2Map from "./steps/2. Map";
import Step3Choice from "./steps/3. Choice";
import Step4Confirm from "./steps/4. Confirm";

export default function ImportDialog({
	open,
	handleClose,
	handleSave,
	values = [],
	dictionary = {}
}) {
	const topContainer = useRef(null);
	// Active step
	const [activeStep, setActiveStep] = useState(0);
	//  Steps labels
	const [steps, setSteps] = useState(["Visalisation", "Association", "Gestion", "Confirmation"]);
	// Can user go to next step
	const [stepValid, setStepValid] = useState(false);
	// Is last step
	const isLastStep = activeStep === steps.length - 1;
	// Errors handle
	function displayError() {
		return null;
	}

	if (lod_.isEmpty(values)) {
		displayError();
	}

	if (lod_.isEmpty(dictionary) || !dictionary.items) {
		displayError();
	}

	const [map, setMap] = useState({});
	const [choice, setChoice] = useState("add");

	// Close dialog
	function close() {
		setActiveStep(0);
		setStepValid(false);
		setMap({});
		setChoice("add");
		handleClose();
	}
	// Import datas
	function sumbit() {
		handleSave({
			mapping: map,
			values: values,
			choice: choice
		});
		close();
		setMap({});
		setChoice("add");
	}
	// User can go to next step
	const validStep = (val = true) => {
		setStepValid(val);
	};
	// Go to next step
	const handleNext = () => {
		setActiveStep(activeStep + 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};
	// Go to previous step
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setStepValid(false);
		topContainer?.current?.scrollIntoView();
	};

	const handleChoice = value => {
		setChoice(value);
	};
	// Get actual step content
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return <Step1View validStep={validStep} datas={values} />;
			case 1:
				return (
					<Step2Map
						validStep={validStep}
						values={values}
						dictionary={dictionary}
						map={map}
						setMap={setMap}
					/>
				);
			case 2:
				return <Step3Choice validStep={validStep} choice={choice} handleChoice={handleChoice} />;
			case 3:
				return <Step4Confirm validStep={validStep} choice={choice} />;
			default:
				return null;
		}
	}

	return (
		<Dialog
			fullWidth
			maxWidth="xxl"
			PaperProps={{
				sx: {
					height: "90%"
				}
			}}
			open={open}
			onClose={close}
		>
			<DialogTitle>
				<MDBox>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</MDBox>
			</DialogTitle>
			<DialogContent>
				<div data-id="top-container" ref={topContainer}></div>
				<MDBox
					mt={2}
					style={{
						height: "60vh"
					}}
				>
					{getStepContent(activeStep)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				{activeStep > 0 && (
					<MDButton variant="contained" color="dark" onClick={handleBack}>
						Retour
					</MDButton>
				)}
				{activeStep < steps.length - 1 && (
					<MDButton variant="contained" color="dark" onClick={handleNext} disabled={!stepValid}>
						Suivant
					</MDButton>
				)}
				{activeStep === steps.length - 1 && (
					<MDButton
						disabled={!stepValid}
						variant="contained"
						color="info"
						onClick={!isLastStep ? handleNext : sumbit}
					>
						Confirmer
					</MDButton>
				)}
			</DialogActions>
		</Dialog>
	);
}
