import { Card, CardContent } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";

import ImageProviderOpenAI from "assets/images/provider/provider-openai.png";
import ImageProviderMistralAI from "assets/images/provider/provider-mistral.png";
import ImageProviderAzureOpenAI from "assets/images/provider/provider-azure-openai.svg";

import formActions from "redux-react/actions/formAction";
import faiqActions from "redux-react/actions/faiqActions";
import { useDispatch, useSelector } from "react-redux";
/**
 * Display the correct provider with image
 * @returns
 */
const ProviderBox = ({ provider, color }) => {
	switch (provider) {
		case "ChatOpenAI":
			return (
				<MDBox display="flex" alignItems="center">
					<MDBox mr={1} display="flex" alignItems="center">
						<img src={ImageProviderOpenAI} alt="OpenAI" width="20" />
					</MDBox>
					<MDTypography variant="body1" fontSize="medium" color={color}>
						OpenAI
					</MDTypography>
				</MDBox>
			);
		case "MistralAI":
			return (
				<MDBox display="flex" alignItems="center">
					<MDBox mr={1} display="flex" alignItems="center">
						<img src={ImageProviderMistralAI} alt="MistralAI" width="20" />
					</MDBox>
					<MDTypography variant="body1" fontSize="medium" color={color}>
						MistralAI
					</MDTypography>
				</MDBox>
			);
		case "AzureOpenAI":
			return (
				<MDBox display="flex" alignItems="center">
					<MDBox mr={1} display="flex" alignItems="center">
						<img src={ImageProviderAzureOpenAI} alt="AzureOpenAI" width="20" />
					</MDBox>
					<MDTypography variant="body1" fontSize="medium" color={color}>
						Azure OpenAI
					</MDTypography>
				</MDBox>
			);
		default:
			return (
				<MDTypography variant="body1" fontSize="medium" color={color}>
					{provider}
				</MDTypography>
			);
	}
};
/**
 * Card for a model
 * @returns
 */
const ModelCard = ({ model, currentModel, selectModel }) => {
	return (
		<MDBox
			mt={1}
			p={1}
			borderRadius="lg"
			bgColor={currentModel === model.code ? "info" : "light"}
			style={{
				cursor: "pointer",
				minHeight: "15vh"
			}}
			display="flex"
			justifyContent="space-between"
			flexDirection="column"
			shadow="md"
			variant={currentModel === model.code ? "gradient" : "contained"}
			onClick={() => selectModel(model)}
		>
			<MDTypography
				variant="h5"
				fontSize="medium"
				color={currentModel === model.code ? "white" : "dark"}
			>
				{model.name}
			</MDTypography>
			<MDBox>
				<MDTypography
					variant="body2"
					fontSize="small"
					color={currentModel === model.code ? "white" : "dark"}
				>
					{model.description}
				</MDTypography>
				<MDBox mt={1}>
					<ProviderBox
						provider={model.provider}
						color={currentModel === model.code ? "light" : "dark"}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};
/**
 * Sort models by provider
 * @returns
 */
function sortModels(models) {
	return models.sort((a, b) => {
		if (a.provider < b.provider) return -1;
		if (a.provider > b.provider) return 1;
		return 0;
	});
}
/**
 * Main component for models
 * @returns
 */
export default function ModelsPart() {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);

	const [models, setModels] = useState([]);
	const [currentModel, setCurrentModel] = useState(null);

	const STANDARD_MODELS = sortModels(models.filter(model => model.category === "standard"));
	const MEDIUM_MODELS = sortModels(models.filter(model => model.category === "medium"));
	const ADVANCED_MODELS = sortModels(models.filter(model => model.category === "advanced"));
	const CUSTOM_MODELS = sortModels(models.filter(model => model.category === "custom"));

	const selectModel = model => {
		// Save for front
		setCurrentModel(model.code);
		// Save in DB
		dispatch(
			faiqActions.saveModelForProcess(
				profile.assistantID,
				{
					code: "replyQuestion",
					model: model.code,
					stepIndex: 0
				},
				res => {}
			)
		);
	};

	useEffect(() => {
		// On load, get all models
		dispatch(
			formActions.getItemsFromCollection(
				"llmModel",
				{
					query: {
						documentType: "llmModel",
						active: true
					}
				},
				res => {
					setModels(res?.items ?? []);
				}
			)
		);
		// On load, get the current model
		dispatch(
			formActions.getItemsFromCollection(
				"llmProcess",
				{
					query: {
						code: "replyQuestion",
						active: true
					}
				},
				res => {
					let process = res?.items?.[0];
					if (process) {
						let model = process?.steps?.[0]?.modelCode;
						setCurrentModel(model);
					}
				}
			)
		);
	}, []);

	return (
		<MDBox mt={3}>
			<MDTypography variant="h3">Choix du modèle</MDTypography>

			<MDBox mt={3}>
				<Card>
					<CardContent>
						<MDBox display="flex">
							<MDBox flex="1" m={0.5}>
								<MDBox>
									<MDTypography variant="h4" fontWeight="bold">
										Standard
									</MDTypography>
								</MDBox>
								<MDBox>
									{!STANDARD_MODELS.length && (
										<MDTypography variant="caption" fontSize="large">
											Aucun modèle
										</MDTypography>
									)}
									{STANDARD_MODELS.map((model, index) => {
										return (
											<ModelCard
												key={index}
												model={model}
												currentModel={currentModel}
												selectModel={selectModel}
											/>
										);
									})}
								</MDBox>
							</MDBox>
							<MDBox flex="1" m={0.5}>
								<MDBox>
									<MDTypography variant="h4" fontWeight="bold">
										Medium
									</MDTypography>
								</MDBox>
								<MDBox>
									{!MEDIUM_MODELS.length && (
										<MDTypography variant="caption" fontSize="large">
											Aucun modèle
										</MDTypography>
									)}
									{MEDIUM_MODELS.map((model, index) => {
										return (
											<ModelCard
												key={index}
												model={model}
												currentModel={currentModel}
												selectModel={selectModel}
											/>
										);
									})}
								</MDBox>
							</MDBox>
							<MDBox flex="1" m={0.5}>
								<MDBox>
									<MDTypography variant="h4" fontWeight="bold">
										Advanced
									</MDTypography>
								</MDBox>
								<MDBox>
									{!ADVANCED_MODELS.length && (
										<MDTypography variant="caption" fontSize="large">
											Aucun modèle
										</MDTypography>
									)}
									{ADVANCED_MODELS.map((model, index) => {
										return (
											<ModelCard
												key={index}
												model={model}
												currentModel={currentModel}
												selectModel={selectModel}
											/>
										);
									})}
								</MDBox>
							</MDBox>
							<MDBox flex="1" m={0.5}>
								<MDBox>
									<MDTypography variant="h4" fontWeight="bold">
										Custom
									</MDTypography>
								</MDBox>
								<MDBox>
									{!CUSTOM_MODELS.length && (
										<MDTypography variant="caption" fontSize="large">
											Aucun modèle
										</MDTypography>
									)}
									{CUSTOM_MODELS.map((model, index) => {
										return (
											<ModelCard
												key={index}
												model={model}
												currentModel={currentModel}
												selectModel={selectModel}
											/>
										);
									})}
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>
			</MDBox>
		</MDBox>
	);
}
