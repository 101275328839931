/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import { Grid, Icon, IconButton, Table, TableBody, TableCell, TableRow } from "@mui/material";
import lod_ from "lodash";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";

const ImportRow = ({ col, map, dictionary, setMap }) => {
	const [achorEl, setAnchorEl] = useState(null);
	const [value, setValue] = useState("");

	const handleClick = path => {
		path = path.replaceAll(".", ".items.");
		let item = lod_.get(dictionary, path);
		let label = item.label.fr;
		setValue(label);
		setAnchorEl(null);
		let defaultPath = path.replaceAll(".items.", ".");
		setMap(map => {
			return { ...map, [col]: defaultPath };
		});
	};

	const handleClickRemove = () => {
		setValue("");
		setMap(map => {
			delete map[col];
			return map;
		});
	};

	useEffect(() => {
		let path = lod_.get(map, col);
		if (path) {
			path = path.replaceAll(".", ".items.");
			let item = lod_.get(dictionary, path);
			let label = item.label.fr;
			setValue(label);
		}
	}, []);

	return (
		<TableRow>
			<TableCell>{col}</TableCell>
			<TableCell>
				<MDInput disabled value={value} />
			</TableCell>
			<TableCell>
				<MDBox m={1} display="flex">
					<MDButton variant="gradient" color="info" onClick={e => setAnchorEl(e.target)}>
						Selectionner
					</MDButton>
				</MDBox>
				<DictionaryMenu
					placement="right"
					dictionary={dictionary}
					anchorEl={achorEl}
					handleInsertText={handleClick}
					handleClose={() => setAnchorEl(null)}
				/>
			</TableCell>
			<TableCell>
				<IconButton onClick={handleClickRemove}>
					<Icon>close</Icon>
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default function Step2Map({ values, dictionary, map, setMap, validStep }) {
	function displayError() {
		return null;
	}

	if (lod_.isEmpty(values)) {
		displayError();
	}

	if (lod_.isEmpty(dictionary) || !dictionary.items) {
		displayError();
	}

	let columns = values[0] ? Object.keys(values[0]) : [];

	dictionary = dictionary.items;

	useEffect(() => {
		validStep();
	}, []);

	return (
		<MDBox
			sx={{ ml: 30, mr: 30 }}
			display="flex"
			alignItems="center"
			style={{
				height: "100%"
			}}
		>
			<Table>
				<TableRow>
					<TableCell>
						<MDTypography variant="h6" component="td">
							Colonnes trouvées
						</MDTypography>
					</TableCell>
					<TableCell>
						<MDTypography variant="h6" component="td">
							Correspond à
						</MDTypography>
					</TableCell>
					<TableCell></TableCell>
					<TableCell></TableCell>
				</TableRow>
				<TableBody>
					{columns.map((col, index) => {
						return (
							<ImportRow key={index} col={col} dictionary={dictionary} map={map} setMap={setMap} />
						);
					})}
				</TableBody>
			</Table>
		</MDBox>
	);
}
