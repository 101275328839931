/* eslint-disable object-shorthand */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { setFilter, deleteFilter } from "redux-react/reducers/filtersReducers";
import lod_ from "lodash";
import ButtonMenu from "../ButtonMenu";
import DateFilter from "./DateFilter";
import { getInterval } from "../DatePresets";
/**
 * Get filters from local storage for the front menus
 */
function getLocalStorageFrontValues(assistantID, page, attribute, filters) {
	// Get filters for assistant
	let actualFilters = filters[assistantID];
	if (!actualFilters) return null;
	// Get filters for assistant > page
	actualFilters = actualFilters[page];
	if (!actualFilters) return null;
	// Get filters for assistant > page > attribute
	let actualFilter = actualFilters[attribute];
	if (!actualFilter) return null;
	// Return front value
	switch (actualFilter.type) {
		case "datetime": {
			// Mode auto = recompute interval
			if (actualFilter.mode === "auto" && actualFilter.code) {
				let interval = getInterval(actualFilter.code);
				return interval;
			} else {
				return actualFilter.values;
			}
		}
		default:
			return actualFilter.front;
	}
}
/**
 * Get filters from local storage for the back requests
 */
export function getLocalStorageBackValues(assistantID, page, filters) {
	// Get filters for assistant
	let actualFilters = filters[assistantID];
	if (!actualFilters) return {};
	// Get filters for assistant > page
	actualFilters = actualFilters[page];
	if (!actualFilters) return {};
	// Map filters
	let keys = Object.keys(actualFilters);
	let backFilters = {};
	for (let key of keys) {
		switch (actualFilters[key].type) {
			case "datetime": {
				let filter = actualFilters[key];
				// Mode auto = recompute interval
				if (filter.mode === "auto" && filter.code) {
					let interval = getInterval(filter.code);
					backFilters[key] = { name: filter.attribute, type: filter.type, value: interval };
				} else {
					backFilters[key] = { name: filter.attribute, type: filter.type, value: filter.values };
				}
				break;
			}
			default:
				backFilters[key] = actualFilters[key].back;
				break;
		}
	}
	return backFilters;
}
/**
 * Parse filters defined by string into
 * an component's array
 * @param {[String]} filters
 */
export function parseFilters(assistantID, page, filters, storeFilters, dispatch) {
	let newComponentFilters = [];

	if (!filters) {
		return newComponentFilters;
	}

	function setFilterToStore(assistantID, page, filter, front, back) {
		dispatch(
			setFilter({
				assistantID,
				page,
				attribute: filter.attribute,
				front: front,
				back: {
					name: filter.attribute,
					value: back,
					type: filter.type
				}
			})
		);
	}

	for (let filter of filters) {
		switch (true) {
			case filter.type === "boolean":
				newComponentFilters.push(
					<MDBox key={filter.attribute} mr={1}>
						<ButtonMenu
							fullHeight
							title={filter.label.fr}
							type="boolean"
							inverted={filter.inverted}
							optionnal={filter.optionnal}
							storage={getLocalStorageFrontValues(
								assistantID,
								page,
								filter.attribute,
								storeFilters
							)}
							handleUpdate={items => {
								if (lod_.isEmpty(items)) {
									let filterObject = {
										assistantID,
										page,
										attribute: filter.attribute
									};

									dispatch(deleteFilter(filterObject));
								} else {
									let value = lod_.isObject(items.value) ? items.value : { $in: [items.value] };
									setFilterToStore(assistantID, page, filter, items, value);
								}
							}}
						/>
					</MDBox>
				);
				break;
			case filter.type === "datetime":
			case filter.type === "date":
				newComponentFilters.push(
					<MDBox mr={1} key={filter.attribute} display="flex">
						<DateFilter
							assistantID={assistantID}
							page={page}
							storage={getLocalStorageFrontValues(
								assistantID,
								page,
								filter.attribute,
								storeFilters
							)}
							tooltip
							attribute={filter.attribute}
							title={filter.label.fr}
						/>
					</MDBox>
				);
				break;
			case filter.type === "number":
			case filter.type === "code":
			case filter.type === "string" && filter.isArray === true:
				newComponentFilters.push(
					<MDBox key={filter.dictionaryPath} mr={1}>
						<ButtonMenu
							fullHeight
							title={filter.label.fr}
							items={filter.values}
							storage={getLocalStorageFrontValues(
								assistantID,
								page,
								filter.attribute,
								storeFilters
							)}
							handleUpdate={items => {
								if (!items.length) {
									let filterObject = {
										assistantID,
										page,
										attribute: filter.attribute
									};

									dispatch(deleteFilter(filterObject));
								} else {
									let values = items.map(i => i.value);
									setFilterToStore(assistantID, page, filter, items, { $in: values });
								}
							}}
						/>
					</MDBox>
				);
				break;
			default:
				newComponentFilters.push(
					<MDBox key={filter.attribute} mr={1}>
						<MDInput
							label={filter.label.fr}
							type={filter.type}
							value={getLocalStorageFrontValues(assistantID, page, filter.attribute, storeFilters)}
							onChange={e => {
								if (!e.target.value.trim()) {
									dispatch(
										deleteFilter({
											assistantID,
											page,
											attribute: filter.attribute
										})
									);
								} else {
									setFilterToStore(assistantID, page, filter, e.target.value, e.target.value);
								}
							}}
						/>
					</MDBox>
				);
				break;
		}
	}

	return newComponentFilters;
}
