import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	/* Get one dictionary by code */
	getDictionary: (code, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/dictionaries/code/${code}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	/* Get dictionaries mappings */
	getDictionariesMappings: (onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/dictionaries/mappings`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	/* Get all dictionaries */
	getDictionaries: (onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/dictionaries`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
						onFailure(err);
					}
				})
			);
		};
	},
	insertDictionary: (assistantID, data, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/dictionary/${assistantID}`,
					data,
					onSuccess,
					onFailure
				})
			);
		};
	},
	getRoles: onSuccess => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/roles`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getSettings: (assistantID, type, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/settings/${assistantID}/${type}`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	newSetting: (assistantID, category, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/settings/${assistantID}/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	editSetting: (assistantID, category, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/settings/${assistantID}/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	deleteSetting: (assistantID, category, query, onSuccess) => {
		let data = {
			query
		};
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/settings/${assistantID}/${category}`,
					data,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	},
	reorderPage: (assistantID, datas, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/settings/page/${assistantID}/${datas.from}/${datas.to}`,
					onSuccess,
					data: {},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
