/* eslint-disable no-debugger */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable object-shorthand */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import lod_ from "lodash";
import ChartsActions from "redux-react/actions/chartsActions";
import { Card, Fab, Fade, Icon, Switch, Tab, Tabs, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MDBadge from "components/Basics/MDBadge";
import { LittleForm } from "components/Custom/LittleForm";
import ConditionBox from "./ConditionBox";
import { display } from "redux-react/reducers/snackBarReducer";
import { Save } from "@mui/icons-material";
import i18n from "i18n";

/**
 * Component to display rule from "ruleConfig", and edit them
 * @param {*} props
 * @returns
 */
export default function RuleComponent(props) {
	const options = props.options ?? {};
	const [rule, setRule] = useState(lod_.cloneDeep(props.rule));
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);
	const [canSaveChanges, setCanSaveChanges] = useState(false);
	/* Update condition's name by it's index */
	const updateLogic = (logic, index) => {
		setRule(prevState => {
			prevState.conditions[index].logic = logic;
			return {
				...prevState,
				conditions: prevState.conditions
			};
		});
	};
	/* Update condition's name by it's index */
	const updateName = (name, key) => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions[key].name = name;
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Update condition's active by it's index */
	const updateActive = (active, key) => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions[key].active = active;
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Update condition's output by it's index */
	const handleSaveOutput = (output, key) => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions[key].output = output;
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Add new condition */
	const addNewCondition = () => {
		// Create empty condition
		let emptyCond = {
			logic: `{ "and": [ ] }`,
			name: `Condition #${rule.conditions.length + 1}`,
			order: rule.conditions.length,
			output: {},
			active: true
		};
		// Add it to the rule
		setRule(prevState => {
			return {
				...prevState,
				conditions: [...prevState.conditions, emptyCond]
			};
		});
	};
	/* Remove condition by it's index */
	const removeCondition = key => {
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			conditions.splice(key, 1);
			return {
				...prevState,
				conditions: conditions
			};
		});
	};
	/* Save updated rule */
	const handleSave = () => {
		dispatch(
			ChartsActions.updateRule(profile.assistantID, props.rule.code, rule, res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.RULES.CALL.successUpdate"),
						type: "success"
					})
				);
			})
		);
	};
	/* Handle drag element */
	const handleElementDrag = ({ destination, source }) => {
		// -> Set "source" element in top of "destination" element
		let sourceIndex = source.index;
		let destinationIndex = destination.index;
		setRule(prevState => {
			let conditions = lod_.clone(prevState.conditions);
			let tempItem = conditions[sourceIndex];

			conditions.splice(sourceIndex, 1);
			conditions.splice(destinationIndex, 0, tempItem);

			conditions.map((cond, index) => {
				cond.order = index;
			});

			return {
				...prevState,
				conditions: conditions
			};
		});
	};

	const [tab, setTab] = useState(0);

	const [defaultOutputSkeleton, setDefaultOutputSkeleton] = useState();
	// createDictionarySkeleton(props.outputDictionary, rule.output.fallback)

	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(defaultOutputSkeleton);
		lod_.set(clone, path, value);
		setDefaultOutputSkeleton(clone);
		setRule(prevState => {
			return {
				...prevState,
				output: {
					...prevState.output,
					fallback: clone
				}
			};
		});
	};

	useEffect(() => {
		if (!lod_.isEqual(rule, props.rule)) {
			setCanSaveChanges(true);
		} else {
			setCanSaveChanges(false);
		}
	}, [rule]);

	return (
		<>
			<Card>
				<MDBox p={3}>
					{/* Top row : name / actions buttons */}
					<MDBox display="flex" alignItems="center" justifyContent="space-between" mb={4}>
						<MDBox display="flex" alignItems="center">
							<MDTypography variant="h2">{rule.name}</MDTypography>
							{!lod_.isEmpty(options) && (
								<MDBox ml={2}>
									<Tabs
										orientation="horizontal"
										value={tab}
										onChange={(e, v) => {
											setTab(v);
										}}
									>
										<Tab
											label="Configuration"
											icon={
												<Icon fontSize="small" sx={{ mt: -0.25 }}>
													looks_one
												</Icon>
											}
										/>
										<Tab
											label="Options"
											icon={
												<Icon fontSize="small" sx={{ mt: -0.25 }}>
													settings
												</Icon>
											}
										/>
									</Tabs>
								</MDBox>
							)}
						</MDBox>
						<MDBox display="flex" alignItems="center">
							<Tooltip
								placement="bottom"
								title={rule.description}
								componentsProps={{
									tooltip: {
										sx: {
											whiteSpace: "pre-line !important",
											textAlign: "left !important"
										}
									}
								}}
							>
								<Icon fontSize="medium">info_outlined</Icon>
							</Tooltip>
							{options.activable && (
								<MDBadge
									color={Boolean(rule.active) ? "success" : "error"}
									badgeContent={Boolean(rule.active) ? "Actif" : "Inactif"}
								/>
							)}
						</MDBox>
					</MDBox>
					{/* Content */}
					{tab === 0 && (
						<MDBox>
							<DragDropContext onDragEnd={handleElementDrag}>
								<Droppable droppableId="dnd-list" direction="vertical">
									{provided => (
										<MDBox {...provided.droppableProps} ref={provided.innerRef}>
											{rule.conditions.map((cond, key) => {
												let condKey = cond.name.toLowerCase().replaceAll(" ", "_");
												return (
													<Draggable key={condKey} index={key} draggableId={condKey}>
														{provided => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															>
																<ConditionBox
																	cond={cond}
																	dictionary={props.dictionary}
																	updateLogic={logic => {
																		updateLogic(logic, key);
																	}}
																	updateName={name => updateName(name, key)}
																	updateActive={active => updateActive(active, key)}
																	outputDictionary={props.outputDictionary}
																	handleSaveOutput={output => {
																		handleSaveOutput(output, key);
																	}}
																	removeCondition={() => {
																		removeCondition(key);
																	}}
																/>
															</div>
														)}
													</Draggable>
												);
											})}
											{provided.placeholder}
											<MDBox mt={3}>
												<MDButton
													fontSize="medium"
													color="light"
													variant="gradient"
													onClick={addNewCondition}
												>
													<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.RULES.addCondition")}
												</MDButton>
											</MDBox>
										</MDBox>
									)}
								</Droppable>
							</DragDropContext>
						</MDBox>
					)}
					{tab === 1 && (
						<MDBox>
							<MDBox>
								<MDTypography variant="h4">{i18n.t("SETTINGS.RULES.options")}</MDTypography>

								{lod_.isEmpty(options) && (
									<MDTypography variant="body2" color="textSecondary">
										{i18n.t("SETTINGS.RULES.noOptionsAvailable")}
									</MDTypography>
								)}

								{options.activable && (
									<MDBox
										mt={3}
										p={1}
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										onClick={() => {
											setRule(prevState => ({ ...prevState, active: !Boolean(prevState.active) }));
										}}
										style={{
											border: "1px solid lightgrey",
											borderRadius: "10px",
											cursor: "pointer"
										}}
									>
										{i18n.t("SETTINGS.RULES.active")}
										<Switch checked={Boolean(rule.active)} />
									</MDBox>
								)}
								{options.fallbackOutput && (
									<MDBox mt={3}>
										<MDTypography variant="h6">
											{i18n.t("SETTINGS.RULES.fallbackOutput")}
										</MDTypography>
										<MDBox mt={2} bgColor="white" borderRadius="lg">
											<LittleForm
												object={props.outputDictionary}
												metadatasSkeleton={defaultOutputSkeleton}
												handleChange={handleChange}
											/>
										</MDBox>
									</MDBox>
								)}
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			</Card>
			<Fade in={canSaveChanges}>
				<div
					style={{
						position: "fixed",
						bottom: 20,
						right: 20,
						zIndex: 1000
					}}
				>
					<Fab variant="extended" color="info" onClick={handleSave}>
						<MDTypography variant="h6" color="light">
							{i18n.t("SETTINGS.RULES.detectedModifications")}
						</MDTypography>
						<MDBox
							bgColor="light"
							p={1}
							borderRadius="lg"
							fontWeight="bold"
							display="flex"
							alignItems="center"
							ml={2}
						>
							<Save sx={{ mr: 1 }} />
							&nbsp;{i18n.t("SETTINGS.save")}
						</MDBox>
					</Fab>
				</div>
			</Fade>
		</>
	);
}
