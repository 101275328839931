/* eslint-disable no-use-before-define */
/* eslint-disable radix */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import MDBox from "components/Basics/MDBox";
import MDDatePicker from "components/Basics/MDDatePicker";
import { ButtonClearFilter } from "components/Custom/Filters/DateFilter";
import { useMaterialUIController } from "context";
import i18n from "i18n";
import { useEffect, useState } from "react";
import lod_ from "lodash";
import {
	Autocomplete,
	Checkbox,
	Chip,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TableCell,
	TableRow,
	Tooltip
} from "@mui/material";
import MDInput from "components/Basics/MDInput";
/**
 * Row for the filters table
 * @returns
 */
const FilterTableLine = ({
	name,
	path,
	value,
	method,
	filter,
	methods,
	handleRemoveFilter,
	onChangeValue
}) => {
	const [selectedMethod, setSelectedMethod] = useState(method || null);
	const [selectedValue, setSelectedValue] = useState(value || "");
	const [validFilter, setValidFilter] = useState(false);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;

	const [inValues, setInValues] = useState(selectedValue || []);

	const valueExist = v => {
		return v !== undefined && v !== null && v !== "";
	};

	useEffect(() => {
		if (selectedMethod !== method) {
			setDefaultValue();
			setInValues([]);
		}
	}, [selectedMethod]);
	/**
	 * Get the correct input to select value for the filter
	 * @returns
	 */
	const getValues = () => {
		let list = null;
		if (filter.codes) {
			list = filter.codes;
		}

		if (filter.whitelist) {
			let nList = {};
			for (let i = 0; i < filter.whitelist.length; i++) {
				let whiteListValue = filter.whitelist[i];

				let correctType = filter.type;

				switch (correctType) {
					case "number":
						whiteListValue = parseInt(whiteListValue);
						break;
					case "string":
						whiteListValue = whiteListValue.toString();
						break;
					default:
						break;
				}

				nList[filter.whitelist[i]] = whiteListValue;
			}
			list = nList;
		}

		if (["$in", "$nin"].includes(selectedMethod)) {
			if (!list) {
				list = [];
			}

			return (
				<Autocomplete
					value={inValues}
					onChange={(event, newValue) => {
						setInValues(newValue);
						let mappedValues = newValue.map(item => (item.value ? item.value : item));
						setSelectedValue(mappedValues);
					}}
					multiple
					options={filter.codes || Object.values(list) || []}
					freeSolo
					renderTags={(tags, getTagProps) => {
						return tags.map((option, index) => {
							let label = option;
							if (lod_.isObject(option)) {
								label = option.label;
							}
							return <Chip key={index} label={label} {...getTagProps({ index })} />;
						});
					}}
					renderInput={params => <MDInput {...params} label={name} />}
				/>
			);
		}

		switch (filter.type) {
			case "boolean":
				return (
					// <FormControlLabel
					// 	label="Concurrent"
					// 	control={
					// 		<Checkbox
					// 			checked={Boolean(selectedValue)}
					// 			onClick={e => setSelectedValue(!Boolean(selectedValue))}
					// 		/>
					// 	}
					// />
					<Checkbox
						checked={Boolean(selectedValue)}
						onClick={e => setSelectedValue(!Boolean(selectedValue))}
					/>
				);
			case "datetime":
				return (
					<MDBox
						className="datePickerContainer"
						style={{
							margin: 0
						}}
						mr={1}
					>
						<MDDatePicker
							value={selectedValue}
							options={{
								time_24hr: true,
								enableTime: true,
								dateFormat: "d M Y H:i"
							}}
							onChange={date => {
								setSelectedValue(new Date(date));
							}}
							input={{
								className: "fullWidthDatePicker date_input_" + (darkMode ? "dark" : "light"),
								placeholder: `${filter.label.fr} (${i18n.t("FILTERS.date.start")})`
							}}
						/>
						<ButtonClearFilter updateDate={() => setSelectedValue(null)} />
					</MDBox>
				);
			case "timestamp":
				return (
					<MDBox
						className="datePickerContainer"
						style={{
							margin: 0
						}}
						mr={1}
					>
						<MDDatePicker
							value={selectedValue}
							options={{
								enableTime: true,
								noCalendar: true,
								dateFormat: "H : i",
								time_24hr: true,
								defaultDate: "01:00"
							}}
							onChange={date => {
								let newDate = new Date(date);
								let hours = newDate.getHours();
								let minutes = newDate.getMinutes();
								let timeInTS = hours * 60 * 60 + minutes * 60;

								setSelectedValue(timeInTS);
							}}
							input={{
								className: "fullWidthDatePicker date_input_" + (darkMode ? "dark" : "light"),
								placeholder: `${filter.label.fr} (${i18n.t("FILTERS.date.start")})`
							}}
						/>
					</MDBox>
				);
			default:
				if (list) {
					if (!lod_.isArray(list)) {
						let newArray = [];
						let keys = Object.keys(list);
						for (let key of keys) {
							newArray.push({
								value: key,
								label: list[key]
							});
						}
						list = newArray;
					}
					return (
						<FormControl fullWidth>
							<InputLabel id="select-type">
								{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
							</InputLabel>
							<Select
								labelId="select-type"
								value={selectedValue}
								label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.value")}
								onChange={e => setSelectedValue(e.target.value)}
							>
								{list.map((item, index) => {
									return (
										<MenuItem key={index} value={item.value}>
											{item.label}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					);
				} else {
					return (
						<MDInput
							type={filter.type}
							value={selectedValue}
							fullWidth
							label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.attribute")}
							onChange={e =>
								setSelectedValue(filter.type === "number" ? Number(e.target.value) : e.target.value)
							}
						/>
					);
				}
		}
	};
	// Update row when filter change
	useEffect(() => {
		onChangeValue({
			name,
			path,
			filter,
			method: selectedMethod,
			value: selectedValue
		});

		setValidFilter(selectedMethod && valueExist(selectedValue));
	}, [selectedMethod, selectedValue]);

	const setDefaultValue = () => {
		// default values
		switch (filter.type) {
			case "timestamp":
				// set default value to 01:00
				setSelectedValue(1 * 60 * 60 + 0 * 60);
				break;
			case "boolean":
				setSelectedValue(Boolean(selectedValue));
				break;
			default:
				setSelectedValue(value ?? "");
				break;
		}
	};

	const setDefaultMethod = () => {
		// default values
		switch (filter.type) {
			case "boolean":
				setSelectedMethod("$eq");
				break;
			default:
				setSelectedMethod(method ?? null);
				break;
		}
	};

	useEffect(() => {
		setDefaultMethod();
		setDefaultValue();
	}, [filter]);

	return (
		<TableRow>
			<TableCell>{filter.label.fr}</TableCell>
			{filter.type === "boolean" ? (
				<TableCell></TableCell>
			) : (
				<TableCell>
					<FormControl fullWidth>
						<InputLabel id="select-type">
							{i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.method")}
						</InputLabel>
						<Select
							labelId="select-type"
							value={selectedMethod}
							label={i18n.t("SETTINGS.CHARTS.COMPUTE.TEXT.method")}
							onChange={e => setSelectedMethod(e.target.value)}
						>
							{methods.map((method, index) => {
								return (
									<MenuItem key={index} value={method.code}>
										{i18n.t(method.label)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</TableCell>
			)}
			<TableCell>{getValues()}</TableCell>
			<TableCell>
				<Tooltip
					title={
						validFilter
							? i18n.t("SETTINGS.CHARTS.COMPUTE.validFilter")
							: i18n.t("SETTINGS.CHARTS.COMPUTE.invalidFilter")
					}
					arrow
					placement="top"
				>
					<MDBox display="flex" p={1}>
						{validFilter ? <Icon color="success">check</Icon> : <Icon color="error">close</Icon>}
					</MDBox>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip placement="top" title={i18n.t("SETTINGS.delete")} arrow>
					<IconButton
						onClick={() => {
							handleRemoveFilter(name);
						}}
					>
						<Icon>delete</Icon>
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};

export default FilterTableLine;
