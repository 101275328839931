import { api } from "./api";

const actions = {
	getWebsiteInformationsFromUrl: (assistantID, url, onSuccess, onFailure) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/files/url/${assistantID}`,
					data: {
						url
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						onFailure(err);
					}
				})
			);
		};
	}
};

export default actions;
