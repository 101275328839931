/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Other settings
 *
 * #######################################################@
 */
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import {
	Card,
	CardContent,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Icon
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import SettingsActions from "redux-react/actions/settingsActions";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import { useState } from "react";
import i18n from "i18n";
import { display } from "redux-react/reducers/snackBarReducer";
import FormDictionary from "./Dictionary/FormDictionary";

const GetCorrectForm = ({ type, submit }) => {
	switch (type) {
		case "dictionary":
			return <FormDictionary submit={submit} />;
		default:
			return <DialogContent>Not found</DialogContent>;
	}
};

const AddChartEnyity = ({ icon, text, type }) => {
	const [open, setOpen] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [datas, setDatas] = useState({});
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const submit = ({ valid, data }) => {
		setDatas(data);
		setIsValid(valid);
	};

	const clickSubmit = () => {
		switch (type) {
			case "dictionary":
				dispatch(
					SettingsActions.insertDictionary(
						profile.assistantID,
						datas,
						e => {
							dispatch(
								display({
									type: "success",
									message: i18n.t("SETTINGS.DICTIONARY.CALL.successCreate")
								})
							);
							setOpen(false);
						},
						e => {
							let message = e?.response?.data?.error?.message;
							dispatch(
								display({
									type: "error",
									message: i18n.t(`SETTINGS.DICTIONARY.CALL.${message || "errorCreate"}`)
								})
							);
						}
					)
				);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<MDButton color="info" onClick={() => setOpen(true)}>
				<Icon>{icon}</Icon>&nbsp;{text}
			</MDButton>
			<Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
				<DialogContent>
					<GetCorrectForm type={type} submit={submit} />
				</DialogContent>
				<DialogActions>
					<MDButton variant="outlined" color="dark" onClick={() => setOpen(false)}>
						{i18n.t("SETTINGS.cancel")}
					</MDButton>

					<MDButton variant="contained" color="dark" onClick={clickSubmit} disabled={!isValid}>
						{i18n.t("SETTINGS.add")}
					</MDButton>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default function SettingsOthers({ route }) {
	return (
		<DashboardLayout>
			<DashboardNavbar filters={[]} />
			<Divider />
			<Card>
				<CardContent>
					<MDTypography>{i18n.t("SETTINGS.OTHERS.moreSettings")}</MDTypography>
					<MDBox display="flex" alignItems="center">
						{/* <MDBox m={1}>
							<AddChartEnyity icon="add" text="Ajouter une règle de gestion" type="rule" />
						</MDBox> */}
						<MDBox m={1}>
							<AddChartEnyity
								icon="add"
								text={i18n.t("SETTINGS.DICTIONARY.addDictionary")}
								type="dictionary"
							/>
						</MDBox>
					</MDBox>
				</CardContent>
			</Card>
		</DashboardLayout>
	);
}
