/* eslint-disable no-unreachable */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-self-compare */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { CircularProgress, Divider, Icon, IconButton, Menu, MenuItem } from "@mui/material";
import { v4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import ProfileActions from "redux-react/actions/profileActions";
import SettingsActions from "redux-react/actions/settingsActions";
import FormActions from "redux-react/actions/formAction";
import { display } from "redux-react/reducers/snackBarReducer";

import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import PagesDataTable from "components/Custom/Tables/PagesDataTable";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { t } from "i18next";
import AddPageDialog from "./AddPageDialog";
import EditPageDialog from "./EditPageDialog";
import DuplicatePageDialog from "./DuplicatePageDialog";

export default function SettingsPages({ route }) {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();

	const [paginedList, setPaginedList] = useState(null);
	const [filtersList, setFiltersList] = useState(null);

	const [addNewPage, setAddNewPage] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [editPage, setEditPage] = useState({ open: false });
	// Confirm dialog
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});

	const [duplicateDialog, setDuplicateDialog] = useState({
		open: false,
		page: {}
	});

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});
	/**
	 * Load pagined list
	 */
	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList[0]);
			setTableLoading(false);
		};

		dispatch(ChartsActions.getPaginedList(profile.assistantID, route, onSuccess));
		// get filters
		dispatch(
			SettingsActions.getSettings(profile.assistantID, "filters", res => {
				setFiltersList(res.documents);
			})
		);
	}
	/**
	 * Save new page
	 */
	function handleSaveNewPage(page) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.PAGES.SUCCESS.add"),
					type: "success"
				})
			);
			loadPaginedList();
			dispatch(ProfileActions.updateMenu(profile.assistantID));
		};
		dispatch(SettingsActions.newSetting(profile.assistantID, "page", page, onSuccess));
	}
	/**
	 * Handle page reorder
	 * @param {Number} from
	 * @param {Number} to
	 */
	function handleReorderPage(from, to) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.PAGES.SUCCESS.edit"),
					type: "success"
				})
			);

			loadPaginedList();
			dispatch(ProfileActions.updateMenu(profile.assistantID));
		};
		dispatch(SettingsActions.reorderPage(profile.assistantID, { from, to }, onSuccess));
	}
	/**
	 * Handle edit page
	 */
	function actionEditHandle(page) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.PAGES.SUCCESS.edit"),
					type: "success"
				})
			);
			loadPaginedList();
			dispatch(ProfileActions.updateMenu(profile.assistantID));
		};
		dispatch(SettingsActions.editSetting(profile.assistantID, "page", page, onSuccess));
	}
	/**
	 * Handle delete page
	 */
	function actionDeleteHandle(page) {
		const handleConfirmDialog = () => {
			setConfirmDialog({
				open: false
			});
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.PAGES.SUCCESS.delete"),
						type: "success"
					})
				);
				loadPaginedList();
				dispatch(ProfileActions.updateMenu(profile.assistantID));
			};
			dispatch(
				SettingsActions.deleteSetting(profile.assistantID, "page", { route: page.route }, onSuccess)
			);
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} : ${i18n.t(`SETTINGS.PAGES.${page.type}`)} ${
				page.name ? `"${page.name}"` : ""
			}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} : ${i18n.t(
				`SETTINGS.PAGES.${page.type}`
			)} ${page.name ? `"${page.name}"` : ""} ?`,
			handleConfirm: handleConfirmDialog
		});
	}

	/**
	 * Duplicate
	 */
	const duplicateAction = (oldPage, newPageName) => {
		// 1- Duplicate page
		dispatch(
			FormActions.duplicateItem(
				{
					query: { route: oldPage.route, documentType: "page" },
					collection: "analytics",
					// updateFields: ["code"],
					setFields: {
						code: v4(),
						name: newPageName,
						route: `/${newPageName.toLowerCase().trim().replaceAll(" ", "-")}`
					}
				},
				res => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateSuccess"),
							type: "success"
						})
					);
					loadPaginedList();
					dispatch(ProfileActions.updateMenu(profile.assistantID));
				},
				err => {
					dispatch(
						display({
							message: i18n.t("FORMS.duplicateError"),
							type: "error"
						})
					);
				}
			)
		);

		// 2- Duplicate charts on page
		dispatch(
			FormActions.duplicateItem(
				{
					query: { "display.pages": [oldPage.route.replace("/", "")], documentType: "chart" },
					collection: "analytics",
					updateFields: ["code"],
					setFields: {
						"display.pages": [`${newPageName.toLowerCase().trim().replaceAll(" ", "-")}`]
					}
				},
				() => {},
				() => {}
			)
		);
	};
	/**
	 * When user create a page, set page's rank to the next rank
	 */
	function getNextRank(rows) {
		const maxRank = rows.reduce((acc, row) => {
			if (row.rank > acc) {
				return row.rank;
			}
			return acc;
		}, 0);
		return maxRank + 1;
	}

	/**
	 * Load pages list on mount
	 */
	useEffect(() => {
		loadPaginedList();
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDButton
						style={{ height: "100%", marginRight: "0.75rem" }}
						variant="contained"
						color="info"
						onClick={() => setAddNewPage(true)}
					>
						<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.PAGES.addPage")}
					</MDButton>
				]}
			/>
			<Divider />
			{paginedList && (
				<div
					style={{
						position: "relative",
						width: "100%",
						height: "100%"
					}}
				>
					{tableLoading && (
						<div
							style={{
								width: "100%",
								height: "100%",
								backgroundColor: "rgba(0,0,0,0.2)",
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 100,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 10
							}}
						>
							<CircularProgress color="info" />
						</div>
					)}
					<PagesDataTable
						draggable
						table={paginedList.data}
						display={paginedList.request.attributesDisplay}
						actions={[
							<IconButton
								handleclick={({ values }, e) => {
									setMenuValues(values);
									setMenuRef(e.currentTarget);
								}}
							>
								<Icon fontSize="medium">more_vert</Icon>
							</IconButton>
						]}
						dragHandler={({ from, to }) => {
							if (from === to) return;
							setTableLoading(true);
							handleReorderPage(from, to);
						}}
					/>

					<Menu
						open={Boolean(menuRef)}
						anchorEl={menuRef}
						onClose={() => {
							setMenuRef(null);
						}}
					>
						{/* Options */}
						<MenuItem disabled style={{ opacity: 1 }}>
							<MDTypography variant="caption">{t("SETTINGS.edition")}</MDTypography>
						</MenuItem>
						<MenuItem
							onClick={() => {
								setEditPage({ ...menuValues, open: true });
								setMenuRef(null);
							}}
						>
							<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
						</MenuItem>

						<MenuItem
							onClick={() => {
								actionDeleteHandle(menuValues);
								setMenuRef(null);
							}}
							style={{
								color: "red"
							}}
						>
							<Icon fontSize="medium">delete</Icon>
							&nbsp;{i18n.t("SETTINGS.delete")}
						</MenuItem>

						<Divider />

						<MenuItem
							onClick={() => {
								setDuplicateDialog({
									open: true,
									page: menuValues
								});
								setMenuRef(null);
							}}
						>
							<Icon fontSize="medium">copy</Icon>&nbsp;{i18n.t("SETTINGS.duplicate")}
						</MenuItem>
					</Menu>
				</div>
			)}
			{paginedList && filtersList && (
				<AddPageDialog
					dictionary={paginedList.dictionary}
					permissionsList={paginedList.permissionsList}
					open={addNewPage}
					handleCloseDialog={() => setAddNewPage(false)}
					handleSave={handleSaveNewPage}
					rank={getNextRank(paginedList.data.rows)}
					filtersList={filtersList}
				/>
			)}
			{paginedList && filtersList && (
				<EditPageDialog
					dictionary={paginedList.dictionary}
					permissionsList={paginedList.permissionsList}
					open={editPage.open}
					handleCloseDialog={() => setEditPage({ open: false })}
					handleSave={actionEditHandle}
					page={editPage}
					filtersList={filtersList}
				/>
			)}
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
			{/* Duplicate page */}
			<DuplicatePageDialog
				dialog={duplicateDialog}
				setDialog={setDuplicateDialog}
				onSave={duplicateAction}
			/>
		</DashboardLayout>
	);
}
