import axios from "axios";
import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	getTryConfig: (assistantID, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/tryFAIQ/config/${assistantID}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors du chargement de configuration"
							})
						);
					}
				})
			);
		};
	},
	tryFAIQ: (
		assistantID,
		question,
		threadID,
		metadatas,
		datas = {},
		onBeforeStream,
		onDataStream,
		onEndStream,
		onAfterStream,
		onFailure = () => {}
	) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/tryFAIQ/${assistantID}`,
					stream: true,
					data: {
						question,
						threadID,
						metadatas,
						...datas
					},
					onBeforeStream,
					onDataStream,
					onEndStream,
					onAfterStream,
					onFailure
				})
			);
		};
	},
	checkExistingKnowledgeList: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/knowledge/checkExistingKnowledgeList/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression de la connaissance"
							})
						);
					}
				})
			);
		};
	},
	pinnedHistory: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/history/pinned/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression de la connaissance"
							})
						);
					}
				})
			);
		};
	},
	faiqTrain: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/faiqTrain/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression de la connaissance"
							})
						);
					}
				})
			);
		};
	},
	deleteKnowledge: (assistantID, content, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/knowledge/${assistantID}`,
					data: {
						content
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression de la connaissance"
							})
						);
					}
				})
			);
		};
	},
	updateKnowledge: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/knowledge/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la mise à jour de la connaissance"
							})
						);
					}
				})
			);
		};
	},
	saveKnowledge: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/knowledge/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la mise à jour de la connaissance"
							})
						);
					}
				})
			);
		};
	},
	saveFaiqConfig: (assistantID, config, onSuccess) => {
		let data = {
			config
		};
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/faiqConfig/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la mise à jour de la configuration"
							})
						);
					}
				})
			);
		};
	},
	getFaiqConfig: (assistantID, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/faiqConfig/${assistantID}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la récupération de la configuration"
							})
						);
					}
				})
			);
		};
	},
	getFaiqStats: (assistantID, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/faiqStats/${assistantID}`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la récupération des statistiques"
							})
						);
					}
				})
			);
		};
	},
	saveSource: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de l'insertion de la source"
							})
						);
					}
				})
			);
		};
	},
	deleteSource: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/source/${assistantID}/${code}`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression de la source"
							})
						);
					}
				})
			);
		};
	},
	deleteSources: (assistantID, codes, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/source/many/${assistantID}`,
					data: {
						sources: codes
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la suppression des sources"
							})
						);
					}
				})
			);
		};
	},
	updateSource: (assistantID, code, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/source/${assistantID}/${code}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la mise à jour de la source"
							})
						);
					}
				})
			);
		};
	},
	/* Update the metadatas for X sources & sources knowledges */
	updateSourcesMetadatas: (assistantID, data, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/source/metadatas/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de la mise à jour des métadonnées"
							})
						);
					}
				})
			);
		};
	},
	extractSource: (assistantID, code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/extract/${assistantID}/${code}`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: "Erreur lors de l'extraction de la source"
							})
						);
					}
				})
			);
		};
	},
	getConversationByThreadId: (assistantID, threadId, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/faiqConversation/${assistantID}/${threadId}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {}
				})
			);
		};
	},
	getQuestionDetailsByQuestionId: (assistantID, questionId, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/faiqQuestion/${assistantID}/${questionId}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {}
				})
			);
		};
	},
	refreshFaiqApiKey: (assistantID, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/faiqApiKey/${assistantID}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {}
				})
			);
		};
	},
	testFAIQ: (assistantID, uuid, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/testFAIQ/${assistantID}/${uuid}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {}
				})
			);
		};
	},
	saveQuestions: (assistantID, questions, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/questions/${assistantID}`,
					data: {
						questions
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {}
				})
			);
		};
	},
	/* Embed question */
	embedQuestion: (assistantID, data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/questions/embedded/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Delete association */
	deleteAssociation: (assistantID, data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/questions/association/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Save model for a process */
	saveModelForProcess: (assistantID, data, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/process/model/${assistantID}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Generate summary */
	generateSummary: (assistantID, code, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/summary/${assistantID}/${code}`,
					data: {},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Get knowledges by sources code */
	getKnowledgesBySourceCode: (assistantID, code, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/knowledge/source/${assistantID}/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Merge X knowledges */
	mergeKnowledges: (assistantID, uuids, code, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/knowledge/merge/${assistantID}`,
					data: {
						uuids,
						code
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Delete knowledges (>= 1) */
	deleteKnowledges: (assistantID, uuids, code, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "DELETE",
					url: `/api/v1/knowledge/many/${assistantID}`,
					data: {
						uuids,
						code
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	},
	/* Start source process for X sources */
	processSources: (assistantID, sources, onSuccess, onFailure = () => {}) => {
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/source/process/${assistantID}`,
					data: {
						sources
					},
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure
				})
			);
		};
	}
};

export default actions;
