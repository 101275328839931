/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import MDTypography from "components/Basics/MDTypography";

export default function AddKnowledgeDialog({ open, handleCloseDialog, handleSave }) {
	// Future knowledge
	const [knowledge, setKnowledge] = useState({
		content: ""
	});
	// Disable button
	const isDisabled = knowledge.content === "";
	// Close dialog
	function close() {
		handleCloseDialog();
	}
	// Save knowledge
	function sumbit() {
		handleSave({
			...knowledge,
			source: "front",
			edited: true
		});
		close();
	}
	// Reset knowledge
	useEffect(() => {
		setKnowledge({
			content: ""
		});
	}, [open === true]);

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={() => close()}>
			<DialogTitle>{i18n.t("KNOWLEDGE.add")}</DialogTitle>
			<DialogContent>
				{/* Content */}
				<MDBox mt={2}>
					<MDInput
						className="dialogInput"
						label={i18n.t("KNOWLEDGE.ADD.content")}
						value={knowledge.content}
						multiline
						rows={7}
						inputProps={{ maxLength: 2000 }}
						onChange={e =>
							setKnowledge(copy => {
								return {
									...copy,
									content: e.target.value
								};
							})
						}
					/>
					<MDTypography variant="caption" color="textSecondary">
						{knowledge.content.length}/2000
					</MDTypography>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={() => close()}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="dark" onClick={sumbit}>
					{i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
